<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "Agregar nuevo" : "Actualizar" }} ingrediente</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Ingredient Image -->
        <template v-if="dataImg">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
            <span class="text-danger text-sm" v-show="errors.has('ingredient_image')">{{ errors.first('ingredient_image') }}</span>
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" name="ingredient_image">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar imágen</vs-button>
            <!-- <vs-button type="flat" color="#999" @click="dataImg = null">Eliminar imágen</vs-button> -->
          </div>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
          <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </template>

        <!-- NAME -->
        <vs-input label="Nombre" autocomplete="off" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required|max:50'" data-vv-as="Nombre"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- DESCRIPTION -->
        <vs-input label="Descripción" autocomplete="off" v-model="dataDescription" class="mt-5 w-full" name="item-description" v-validate="'required:max:100'" data-vv-as="Descripción"/>
        <span class="text-danger text-sm" v-show="errors.has('item-description')">{{ errors.first('item-description') }}</span>

        <!-- STATUS -->
        <vs-input label="Status" v-model="dataStatus" v-show="false" class="mt-5 w-full" name="item-status" />
        <span class="text-danger text-sm" v-show="errors.has('item-status')">{{ errors.first('item-status') }}</span>

        <!-- Upload -->
        <div class="upload-img mt-5" v-if="!dataImg">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Subir imágen</vs-button>
          <vs-alert v-show="image_error" color="danger" class="mt-5 mb-5" icon-pack="feather" icon="icon-info" style="min-height:62px">{{ image_error }}</vs-alert>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" id="saveButton">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      }else {
        let { id, image, name, description, status } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataImg = this.$baseUrlImg + image
        this.dataName = name
        this.dataDescription = description
        this.dataStatus = status
        this.image_loaded = false
        this.image_error = ''
        this.initValues()
      }

    }
  },
  data() {
    return {

      dataId: null,
      dataName: "",
      dataImg: null,
      dataDescription: '',
      dataStatus: 1,

      ingredient_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },

      notify: {
        title: "Ingrediente",
        color: {true: "success", false: "danger"},
        msg: null
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {

        return !this.errors.any() && this.dataName && this.image_error == '' && this.dataImg != null

    },
    //console: () => console,
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.dataId = null
        this.dataName = ""
        this.dataDescription = ""
        this.dataImg = null
        this.status = 1
        this.image_loaded = false
        this.image_error = ''
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (!result)
          return

        this.disableSaveButton();

        const obj = {
          id: this.dataId,
          name: this.dataName,
          image: (this.image_loaded) ? this.dataImg : '',
          description: this.dataDescription,
          status: this.dataStatus
        }

        let action = (this.dataId !== null && this.dataId >= 0) ? "updateIngredient" : "addIngredient"

        this.$store.dispatch(`dataListIngredients/${action}`, obj).then(() => this.submitSuccess() ).catch(err => { this.submitError(err) })

      })
    },
    submitSuccess() {
      this.enableSaveButton();
      this.notify.msg = 'Se han guardado los cambios.';
      this.showNotify(true);
      this.initValues();
      this.$emit('closeSidebar');
    },
    submitError(msg) {
      this.enableSaveButton();
      this.notify.msg = msg;
      this.showNotify(false)
    },
    showNotify(status) {
      this.$vs.notify({
        color: this.notify.color[status],
        title: this.notify.title,
        text: this.notify.msg,
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon: (status ? 'icon-check' : 'icon-alert-circle'),
      })
    },
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        this.ingredient_image.size = input.target.files[0].size;
        var reader = new FileReader()
        reader.onload = e => {
          let img = new Image();
          img.onload = () => {
            this.ingredient_image.width = img.width;
            this.ingredient_image.height = img.height;
            // console.log(this.ingredient_image.size);
            if (this.ingredient_image.width != this.ingredient_image.height || this.ingredient_image.width > 300 ) {
              this.image_error = 'La imágen debe ser cuadrada (misma altura y ancho) con un máximo de 300px';
              // console.log(this.image_error);
            } else {
              if (this.ingredient_image.size > 100000){
                this.image_error = 'La imágen excede el tamaño máximo (100 KB).';
              } else {
                this.dataImg = e.target.result
                this.image_loaded = true;
              }
            }
          }
          img.src = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  created() {
    // console.log("")
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
