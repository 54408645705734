<template>
  <div id="data-list-thumb-view" class="data-list-container">

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData"/>

    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="ingredients">
      <!-- multiple -->

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center">
          <vs-button class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">Agregar Nuevo</vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ ingredients.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : ingredients.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=50">
              <span>50</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>Imagen</vs-th>
        <vs-th sort-key="name">Nombre</vs-th>
        <vs-th sort-key="description">Descripción</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th>Acción</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td class="img-container">
              <img :src="patch + tr.image" class="product-img" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.description }}</p>
            </vs-td>

            <vs-td>
              <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">{{ getStatusText(tr.status) }}</vs-chip>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <vx-tooltip text="Editar" class="float-left">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
              </vx-tooltip>
              <vx-tooltip :text="tr.status == 1 ? 'Eliminar' : 'Activar'" class="float-left">
                <feather-icon :icon="tr.status == 1 ? 'TrashIcon' : 'RefreshCwIcon'" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr)" />
              </vx-tooltip>
            </vs-td>

          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import DataViewSidebar from './Datalist/DataViewSidebarIngredients.vue'
import moduleDataList from "@/store/data-list-ingredients/moduleDataList.js"

export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      patch: this.$baseUrlImg,

      notify: {
        title: "Ingrediente",
        color: {true: "success", false: "danger"},
        msg: {true: "Se han guardado los cambios.", false: "Error"}
      }
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    ingredients() {
      return this.$store.state.dataListIngredients.ingredients
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.ingredients.length
    }
  },
  methods: {
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData(row) {
      //this.$store.dispatch("dataList/removeItem", id).catch(err => { console.error(err) })
      const obj = {
        id: row.id,
        name: row.name,
        description: row.description,
        image: '',
        status: (row.status == 1) ? 0 : 1
      }
      this.$store.dispatch("dataListIngredients/updateIngredient", obj).then(() => this.showNotify(true) )
      .catch(err => {
        this.notify.false.msg = err
        this.showNotify(false)
      })
    },
    showNotify(status) {
      this.$vs.notify({
        color: this.notify.color[status],
        title: this.notify.title,
        text: this.notify.msg[status],
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon: (status ? 'icon-check' : 'icon-alert-circle'),
      })
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getStatusColor(status) {
      if(status == '1') return "success"
      if(status == '0') return "danger"
      return "primary"
    },
    getStatusText(status) {
      if(status == '1') return "activo"
      if(status == '0') return "inactivo"
      return "primary"
    },
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    }
  },
  created() {
    if(!moduleDataList.isRegistered) {
      this.$store.registerModule('dataListIngredients', moduleDataList)
      moduleDataList.isRegistered = true
    }

    this.$store.dispatch("dataListIngredients/getIngredients")
    // .then(response => {
    //     console.log(response.data.data)
    //     this.data = response.data
    // })
    .catch(error => {console.log(error)})

  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;


      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
            &.img-container {
              // width: 1rem;
              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 110px;
              }
            }
            .product-stock-min {
              max-width: 6rem;
              text-align: center;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
